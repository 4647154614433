/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect,useState} from 'react'
import { useParams } from "react-router-dom";
import {InvoiceExpandDetails} from "../InvoiceExpandDetails"
import {
    Paper,
    Tabs,
    Tab,
    Typography,
    makeStyles,
    Snackbar,
    SnackbarContent,
} from "@material-ui/core";
import clsx from "clsx";
import green from "material-ui/colors/green";
import PropTypes from "prop-types";
import { NODE_API_URL,BASE_URL } from '../../../../env_config';
import axios from 'axios';
import InvoiceDetailSkeleton from '../InvoiceDetailSkeleton';
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment-timezone";
import { MdOutlineEmail } from "react-icons/md";
import { FaRegFilePdf } from 'react-icons/fa6';
import Cookies from "js-cookie";
import getSymbolFromCurrency from 'currency-symbol-map';
  function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

export const InviceDetail = (props) => {
    const [value, setValue] = React.useState(0); // for tab
    const [data,setData] = React.useState([])
    const [invData,setInvData] = React.useState([])
    const [stoneData, setStoneData] = React.useState();
    const {OrderId} = useParams()
    // console.log("OrderId",OrderId)
    function handleTabChange(event, newValue) {
        setValue(newValue);
    }
    const [cgst,setCGST] = useState(0)
    const [sgst,setSGST] = useState(0)
    const [tax,setTAX] = useState(0)
    const [chargetax,setChargeTax] = useState(0)
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState();
    function handleClose(event, reason) {
        setAlertOpen(false);
    }
  
    const useStyles = makeStyles((theme) => ({
        success: {
            backgroundColor: green[600],
        },
        
    }));
  
    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
  
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }
    useEffect(() => {
        // alert(data.length)
        
        const finalData = { 
            "order_id":OrderId
        }
        axios({
            method: "get",
            url: `${NODE_API_URL}/INTL/invoice-detail-page`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            params:finalData
        }).then((res) => {
            // console.log("INVOICE DATA", res.data);
            if (res && res.data) {
                // setDashData(res.data);
                // setSalesData(res.data.salesExecutiveDetail);
                setInvData(res.data)
                setData(res.data[0])
                // console.log(res.data,"DATATAT111")
                setStoneData(res.data[0].expand)
                setChargeTax((((res.data[0].convenience_Fee + res.data[0].discount_extra) * 18)/100))
                if(res.data[0].state === "maharashtra"){
                    setCGST(((res.data[0].diamondPriceExchange * 0.75)/100))
                    setSGST(((res.data[0].diamondPriceExchange * 0.75)/100)) 
    
                }else{
                    setTAX(((res.data[0].diamondPriceExchange * 1.5)/100))
                }

            }
        }).catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
        })
    },[])
    function numberWithCommas(x) {
        if(x){
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        }
    }
    const GetPDF = () => {
        if(process.env.REACT_APP_ENV === "production"){
            window.open(`https://assets.diamondsoncall.com/assets/pdf_invoice/${data.billInvoiceSale}`, "_blank")
        }
        else{
            window.open(`https://alpha.diamondsupload.com/assets/pdf_invoice/${data.billInvoiceSale}`, "_blank")
        }
    }
    const SendEmail = () => {
        const finalData = {
            "order_id":OrderId,
            "conform_good_id":data.confirmGoodsId.split(','),
            "client_id":props.userinfo.id
        }
        let apilink = "https://api.immitation.diamondsupload.com"
        if(process.env.REACT_APP_ENV === "production"){
            apilink = "https://api.oasis.diamondsoncall.online"
        }
        axios({
            method: "POST",
            url: `${apilink}/admin/invoicemail`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data:finalData
        }).then(res => {
            if(res && res.data && res.data.success){
                setAlertMessage(res.data.message)
                if(res.data){
                    setAlertOpen(true)
                    // alert(res.data)
                }
            }
        }).catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
        })
    }
    const TrackOrder = (invoicedata) => {
        if(invoicedata.pre_carriage === "UPS"){
            window.open(`https://wwwapps.ups.com/WebTracking?AgreeToTermsAndConditions=yes&loc=en_IN&tracknum=${invoicedata.tracking_no}&Requester=trkinppg/trackdetails`)
        }
        if(invoicedata.pre_carriage === "FEDEX"){
            window.open(`https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=${invoicedata.tracking_no}&locale=en_US&cntry_code=us`)
            
        }
    }
    const getaddress = () => {
        let shippingaddress = ""
        let billingaddress = ""
        if(invData[0]){
            if(invData[0].company_name_official){
                shippingaddress += "<p><b>"
                shippingaddress += invData[0].company_name_official
                shippingaddress += "</b></p>"
            }
            if(invData[0]?.name_atten){
                shippingaddress += "<p><b>"
                shippingaddress += invData[0]?.name_atten
                shippingaddress += "</b></p>"
            }
            if(invData[0]?.shipping_address_1){
              shippingaddress += "<p>"
              shippingaddress += invData[0]?.shipping_address_1 + ","
              shippingaddress += "</p>"
            }
            if(invData[0]?.shipping_address_2){
                shippingaddress += "<p>"
                shippingaddress += invData[0]?.shipping_address_2 + ","
                shippingaddress += "</p>"
            }
            if(invData[0]?.shipping_address_3){
                shippingaddress += "<p>"
                shippingaddress += invData[0]?.shipping_address_3 + ","
                shippingaddress += "</p>"
            }
            shippingaddress += "<p>"
            shippingaddress += invData[0]?.shipping_city + ", "
            if(invData[0]?.shipping_state){
                shippingaddress += invData[0]?.shipping_state + ", "
            }
            shippingaddress += invData[0]?.shipping_zip_code + ", "
            shippingaddress += "</p>"
            if(invData[0]?.shipping_country){
                shippingaddress += "<p>"
                shippingaddress += invData[0]?.shipping_country
                shippingaddress += "</p>"
            }
            if(invData[0]?.fedex_ac_no){
                shippingaddress += "<p>Fedex Ac No:"
                shippingaddress += invData[0]?.fedex_ac_no
                shippingaddress += "</p>"
            }
            if(invData[0]?.shipping_email){
                shippingaddress += "<p>Email:"
                shippingaddress += invData[0]?.shipping_email
                shippingaddress += "</p>"
            }
            if(invData[0]?.shipping_phone){
                shippingaddress += "<p>Phone:"
                shippingaddress += invData[0]?.shipping_phone
                shippingaddress += "</p>"
            }
        
            
        
            if(invData[0].company_name_official){
                billingaddress += "<p><b>"
                billingaddress += invData[0].company_name_official
                billingaddress += "</b></p>"
            }
            if(invData[0].address){
              billingaddress += "<p>"
              billingaddress += invData[0].address + ","
              billingaddress += "</p>"
            }
            if(invData[0].secaddress){
                billingaddress += "<p>"
                billingaddress += invData[0].secaddress + ","
                billingaddress += "</p>"
            }
            if(invData[0].addressline3){
                billingaddress += "<p>"
                billingaddress += invData[0].addressline3 + ","
                billingaddress += "</p>"
            }
            billingaddress += "<p>"
            billingaddress += invData[0]?.city + ", "
            if(invData[0]?.state){
                billingaddress += invData[0]?.state + ", "
            }
            billingaddress += invData[0]?.com_zip + ","
            billingaddress += "</p>"
            if(invData[0].country){
                billingaddress += "<p>"
                billingaddress += invData[0].country
                billingaddress += "</p>"
            }
            if(invData[0].company_tax){
                billingaddress += "<p><b>Importers Ref:</b>"
                billingaddress += invData[0].company_tax
                billingaddress += "</p>"
            }
        }
        return{
            shippingaddress:shippingaddress,
            billingaddress:billingaddress
        }
}
    return (
        <>
        {invData.length > 0 ?
           <>
           {data ? <>
                <div className="row bg-white m-0 rounded border-left-primary mb-5">
                    <div className="col-12 px-5 py-2">
                        <div className="mb-0 font-size-h3 font-weight-bold d-block tex-dark"> {props.language.PROFORMA_DETAIL_DOCLLP} </div>
                        {/* <div className="text-muted">{props.language.INVOICE_DETAIL_GSTIN} 27AASFD2652D1ZF</div > */}
                        <div className="text-muted">{props.language.INVOICE_DETAIL_ADDRESS}: UNIT E-F, 8/F, 8 Hart Avenue,Tsim Sha Tsui, Kowloon,Hong Kong</div >
                    </div>
                </div>
                <div className="card custom-card border-0 gutter-b">
                    <div className="card-header bg-transparent  py-3 px-5 d-flex justify-content-between align-items-center border-bottom-0">
                        <div className="card-title mb-0 ">
                            <span className="font-size-h3 font-weight-bold d-block">{props.language.INVOICE_DETAIL_INVOICE_DETAIL}: #{invData[0].invoiceCountry === "IND-EXP"?invData[0].export_no:data.invoicenumber} {invData[0].invoiceCountry === "IND-EXP"?"(Shipment From India)":""}</span>
                            <span className="text-muted d-block">
                                {props.language.INVOICE_DETAIL_INVOICED_ON}
                                {/* {data.invoiceDate} */}{" "}
                                { data.invoiceDate ? moment.tz(data.invoiceDate, props.userinfo.timezone ? props.userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}
                                </span >
                        </div>
                        <div className="card-toolbar">
                            <span className="btn btn-text-dark btn-hover-light-dark  mr-3"onClick={() =>window.history.go(-1)}><BsArrowLeftShort className='mr-2 font-size-h5'/> {props.language.BACK}</span>
                            <button className="btn btn-text-dark btn-hover-light-primary  mr-3" onClick={SendEmail}><MdOutlineEmail className='mr-2 font-size-h5'/>{props.language.PROFORMA_DETAIL_SEND_EMAIL}</button>
                            <button className="btn btn-text-dark btn-hover-light-danger " onClick={GetPDF}><FaRegFilePdf className='mr-2 font-size-h5'/>{props.language.PROFORMA_DETAIL_SEND_PDF}</button>
                        </div>
                    </div>
                    <div className="card-body rounded p-0 bg-white pb-8">
                        <Paper position="static" className="shadow-none bg-transparent border-bottom">
                            <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                                <Tab label={props.language.PROFORMA_DETAIL_DETAILS} />
                                {/* <Tab label="History" disabled /> */}
                                {/* <Tab label="Additional Details"/> */}
                            </Tabs>
                        </Paper>
                         {value === 0 && (
                            <TabContainer >
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_SHIPPING_ADDRESS}</div>
                                        <div>
                                        {getaddress().shippingaddress ?  <div className="invAddress" dangerouslySetInnerHTML={ {__html:  getaddress().shippingaddress} } />: '-'}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="font-size-h4 font-weight-bold  mb-2">{props.language.PROFORMA_DETAIL_BILLING_ADDRESS}</div>
                                        <div>
                                        {getaddress().billingaddress?  <div className="invAddress" dangerouslySetInnerHTML={ {__html:  getaddress().billingaddress} } />:  '-'}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_LOGISTICS_INFORMATION}</div>
                                        <div className=""> {data.internationalShippingCompany ? data.internationalShippingCompany : null} </div>
                                        {data.tracking_no?<div>Track Your Package: <a onClick={() => TrackOrder(data)} target="_blank" rel='referal' href='#'>{data.tracking_no}</a></div>:""}
                                        {/* <a href='#' target="_blank" rel='referal'>Track Your Package</a> */}
                                    </div>
                                    <div className="col-md-3">
                                        <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_ORDER_SUMMARY}</div>
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-between">
                                                <span>{props.language.PROFORMA_DETAIL_DIAMONDS}:</span>
                                                <span> {data.totalDiamond ? data.totalDiamond : null} {props.language.PROFORMA_DETAIL_PCS}</span>
                                            </div>
                                            <div className="col-12 d-flex justify-content-between">
                                                <span>{props.language.SEARCHLIST_TOTALCARAT}:</span>
                                                <span>{data.totalCarat ? data.totalCarat : null}</span>
                                            </div>
                                            <div className="col-12 d-flex justify-content-between">
                                                <span>{props.language.SEARCHGRID_TOTAL} USD/CT:</span>
                                                <span>${numberWithCommas(parseFloat(data["totalPriceCT"]))}</span>
                                            </div>
                                            <div className="col-12 d-flex justify-content-between">
                                                <span>{props.language.INVOICE_DETAIL_TOTAL_PRICE} USD:</span>
                                                <span>${numberWithCommas(parseFloat(data.diamondPrice))}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabContainer>
                        )}
                        {/* {value === 1 && (
                            <TabContainer >
                                2
                            </TabContainer>
                        )} */}
                    </div>
                </div>
                <div className="card custom-card border-0 gutter-b">
                    <div className="card-header bg-transparent py-5 border-bottom-0">
                        <div className="card-title mb-0 font-weight-bold font-size-h4 ">{props.language.PROFORMA_DETAIL_DIAMONDS_INFORMATION}</div>
                    </div>
                    <div className="card-body p-8 rounded bg-white">
                       {stoneData ? <InvoiceExpandDetails diamonddetail={stoneData} userinfo={props.userinfo} language={props.language} expandData={invData[0]}/> : null }
                        <div className="row p-5 mt-5">
                            <div className="col-md-7">
                                {/* <div className="font-size-h4  mb-2">NOTES</div>
                                <div>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                </div> */}
                            </div>
                            <div className="col-md-5">
                            <table className='table'>
                                <tr>
                                    <td className="font-size-h5">{props.language.INVOICE_DIAMOND_PRICE}:</td>
                                    <td className="font-size-h5 text-right">
                                        ${numberWithCommas(parseFloat(data.diamondPrice))}
                                        <div className='small text-muted'>$/CT{data["totalPriceCT"] ? numberWithCommas(parseFloat( data["totalPriceCT"])) : null}</div>
                                    </td>
                                    {data.oh_global_curr && data.oh_global_curr !== "USD" ?
                                        <td className="font-size-h5 text-right">
                                           { getSymbolFromCurrency(data.oh_global_curr)}{numberWithCommas(parseFloat(data.diamondPriceExchange))}
                                            <div className='small text-muted'>{data.oh_global_curr}/CT {data["totalPriceCTExchange"] ? numberWithCommas(parseFloat( data["totalPriceCTExchange"])) : null}</div>
                                        </td>
                                    :null}
                                </tr>
                                {data.discountExtra?
                                <tr>
                                    <td className="font-size-h5">{data.discountExtra>0?props.language.INVOICE_EXTRA_CHARGES:props.language.INVOICE_DISCOUNT}:</td>
                                    <td className="font-size-h5 text-right">${numberWithCommas(data.discountExtra)}</td>
                                    {data.oh_global_curr && data.oh_global_curr !== "USD" ?
                                        <td className="font-size-h5 text-right">{ getSymbolFromCurrency(data.oh_global_curr)}{numberWithCommas(data.discountExtraExchange)}</td>
                                    :null}
                                </tr>:null}
                                {data.convenienceFee?<tr className="">
                                    <td className="font-size-h5">{invData[0].invoiceCountry === "IND-EXP"?"Shipping Charges":props.language.REQ_DIAMOND_CONVINIENCE_FEE}:</td>
                                    <td className="font-size-h5  text-right">${numberWithCommas(data.convenienceFee)}</td>
                                    {data.oh_global_curr && data.oh_global_curr !== "USD" ?
                                        <td className="font-size-h5  text-right">{ getSymbolFromCurrency(data.oh_global_curr)}{numberWithCommas(data.convenienceFeeExchange ? data.convenienceFeeExchange:"00")}</td>
                                    :null}
                                </tr>:""}
                                {data.vip_discount?<tr >
                                    <td className='font-size-h5 '>{props.language.INVOICE_SERVICE_CHARGE_DISCOUNT}:</td>
                                    <td className='font-size-h5  text-right text-danger'>$-{numberWithCommas(parseFloat(data.vip_discount))}</td>
                                    {data.oh_global_curr && data.oh_global_curr !== "USD" ?
                                        <td className='font-size-h5  text-right text-danger'>{ getSymbolFromCurrency(data.oh_global_curr)}-{numberWithCommas(parseFloat(data.vip_discount_exchange ? data.vip_discount_exchange:"00"))}</td>
                                    :null}
                                </tr>:""}
                                <tr>
                                    <td className="font-weight-bold font-size-h5">{props.language.REQ_TOTAL_DIAMOND_PRICE}</td>
                                    <td className="font-weight-bold  text-right font-size-h5">${numberWithCommas(parseFloat(data["invoiceFinalAmount"]))}</td>
                                    {data.oh_global_curr && data.oh_global_curr !== "USD" ?
                                        <td className="font-weight-bold  text-right font-size-h5">{ getSymbolFromCurrency(data.oh_global_curr)}{numberWithCommas(parseFloat(data["invoiceFinalAmountExchange"]))}</td>
                                    :null}
                                </tr>
                            </table>
                                {/* <div className="row">
                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 '>USD/CT:</span>
                                        <span className='font-size-h5 '>{data["totalPriceCTExchange"] ?"$" + numberWithCommas(parseFloat( data["totalPriceCTExchange"])) : null}</span>
                                    </div>
                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 '>{props.language.INVOICE_DIAMOND_PRICE}:</span>
                                        <span className='font-size-h5 '>${numberWithCommas(parseFloat(data.diamondPriceExchange))}</span>
                                    </div>
                                    {data.discountExtra?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 '>{data.discountExtra>0?props.language.INVOICE_EXTRA_CHARGES:props.language.INVOICE_DISCOUNT}</span>
                                    <span className='font-size-h5 '>${numberWithCommas(data.discountExtra)}</span>
                                    </div>:""}
                                    {data.convenienceFee?<div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 '>{invData[0].invoiceCountry === "IND-EXP"?"Shipping Charges":props.language.REQ_DIAMOND_CONVINIENCE_FEE}:</span>
                                        <span className='font-size-h5 '>${numberWithCommas(data.convenienceFee)}</span>
                                    </div>:""}
                                    {data.vip_discount?<div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 '>{props.language.INVOICE_SERVICE_CHARGE_DISCOUNT}:</span>
                                        <span className='font-size-h5  text-danger'>${-numberWithCommas(data.vip_discount)}</span>
                                    </div>:""}
                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 font-weight-bold'>{props.language.REQ_DIAMOND_DIAMOND_PRICE}:</span>
                                        <span className='font-size-h5 font-weight-bold'>${numberWithCommas(parseFloat(data["invoiceFinalAmountExchange"]))}</span>
                                    </div>
                                    {data.creditCardCharge?<div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 '>{props.language.INVOICE_CREDIT_CARD_CHARGES}:</span>
                                        <span className='font-size-h5 '>${numberWithCommas(parseFloat(data.creditCardCharge))}</span>
                                    </div>:""}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card bg-white">
                <div className="card-body">
                    {data.oh_global_curr && data.oh_global_curr !== "USD" ?
                        <div className="row">
                            <div className="col-md-4">
                                <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_BANK_DETAILS}</div>
                                    <div className='font-size-lg'>
                                        <div>Account Name : Diamonds On Call Limited</div>
                                        <div>Account Number : 39051002000</div>
                                        <div>Bank Name : CITI BANK (HONG KONG) LIMITED</div>
                                        <div>Bank Address : Citi Tower, 83 Hoi Bun Road,</div>
                                        <div>One Bay East, Kwun Tong</div>
                                        <div>SWIFT Code : CITIHKAXXXX</div>
                                        <div>Intermediary bank : Citi Bank New York</div>
                                        <div>Intermediary swift bank code: CITIUS33XXX</div>
                                        <div>ABA NO: 021000089</div>
                                    </div>
                            </div >
                            { data.oh_global_curr && data.oh_global_curr === "SGD" ? 
                                <div className="col-md-4">
                                    <div className="font-size-h4 font-weight-bold mb-2">SGD Bank Details</div>
                                    <div className='font-size-lg'>
                                        <div>Account name: DIAMONDS ON CALL LIMITED </div>
                                        <div>Account number: 183-946-35 (SGD) </div>
                                        <div>Bank name: Wise Asia-Pacific Pte. Ltd. (Formerly TransferWise) </div>
                                        <div>Bank code: 0516  </div>
                                        <div>Bank address: 1 Paya Lebar Link #13-06 - #13-08 PLQ 2, Paya Lebar Quarter Singapore 408533 </div>
                                        <div>Payment network: FAST</div>
                                    </div>
                                </div>
                            :null}
                            {data.oh_global_curr && data.oh_global_curr === "NZD" ? 
                                <div className="col-md-4">
                                    <div className="font-size-h4 font-weight-bold mb-2">NZD Bank Details</div>
                                    <div className='font-size-lg'>
                                        <div>Account name: DIAMONDS ON CALL LIMITED</div>  
                                        <div>Account number: 04-2021-0262623-09 (NZD) </div>
                                    </div>
                                </div>
                            :null}
                            {data.oh_global_curr && data.oh_global_curr === "AUD" ? 
                                <div className="col-md-4">
                                    <div className="font-size-h4 font-weight-bold mb-2">AUD Bank Details</div>
                                    <div className='font-size-lg'>
                                        <div>Account name: DIAMONDS ON CALL LIMITED</div>  
                                        <div>Account number: 224778982 (AUD)</div> 
                                        <div>Bank name: Wise Australia Pty Ltd</div> 
                                        <div>BSB code: 774001</div>
                                    </div>
                                </div>
                            :null}
                            {data.oh_global_curr && data.oh_global_curr === "EUR" ? 
                                <div className="col-md-4">
                                    <div className="font-size-h4 font-weight-bold mb-2">EUR Bank Details</div>
                                    <div className='font-size-lg'>
                                        <div>Account name: DIAMONDS ON CALL LIMITED</div>  
                                        <div>IBAN: BE63 9051 5151 4108 (EUR)</div> 
                                        <div>Bank name : WISE EUROPE S.A </div>
                                        <div>BIC: TRWIBEB1XXX </div>
                                        <div>Bank address: Wise Rue du Trône 100, 3rd floor Brussels 1050 Belgium </div>
                                    </div>
                                </div>
                            :null}
                            {data.oh_global_curr && data.oh_global_curr === "GBP" ? 
                                <div className="col-md-4">
                                    <div className="font-size-h4 font-weight-bold mb-2">GBP Bank Details</div>
                                    <div className='font-size-lg'>
                                        <div>Account name: DIAMONDS ON CALL LIMITED </div> 
                                        <div>Account number: 49740982 (GBP) </div>
                                        <div>IBAN: GB49 TRWI 2308 0149 7409 82</div>  
                                        <div>Bank name: Wise Payments Limited </div>
                                        <div>Sort code: 23-08-01 </div>
                                        <div>Bank address:  56 Shoreditch High Street London E1 6JJ United Kingdom</div> 
                                    </div>
                                </div>
                            :null}
                            {data.oh_global_curr && data.oh_global_curr === "CAD" ? 
                                <div className="col-md-4">
                                    <div className="font-size-h4 font-weight-bold mb-2">CAD Bank Details</div>
                                    <div className='font-size-lg'>
                                        <div>Account name: DIAMONDS ON CALL LIMITED </div> 
                                        <div>Account number: 200116563930 (CAD) </div>
                                        <div>Bank name :  Peoples Trust</div> 
                                        <div>Institution number: 621 </div>
                                        <div>Transit number: 16001</div> 
                                        <div>Bank address: 595 Burrard Street, Vancouver, BC, V7X 1L7, Canada </div>
                                    </div>
                                </div>
                            :null}
                            {data.oh_global_curr && data.oh_global_curr === "HKD" ? 
                                <div className="col-md-4">
                                    <div className="font-size-h4 font-weight-bold mb-2">HKD Bank Details</div>
                                    <div className='font-size-lg'>
                                        <div>Account name : DIAMONDS ON CALL LIMITED </div>
                                        <div>Account number : 39051002019 (HKD) </div>
                                        <div>FPS ID : 162661904 </div>
                                        <div>Bank Name : CITI BANK (HONG KONG) LIMITED</div> 
                                        <div>Bank address: Citi Tower, 83 Hoi Bun Road, One Bay East, Kwun Tong </div>
                                    </div>
                                </div>
                            :null}
                            
                            <div className="col-md-4">
                            <div className="font-size-h4 font-weight-bold mb-3">Declaration :</div>
                                    <div className="font-size-sm mb-3">
                                        1) We Declare that this Invoice shows the actual price of the goods described and that all particulars are true and correct.
                                    </div>
                                    <div className="font-size-sm mb-3">
                                        2) The diamonds herein invoiced have been purchased from legitimate sources not involved in funding conflict and in compliance with United Nations Resolutions.
                                    </div>
                                    <div className="font-size-sm mb-3">
                                        3) The seller hereby guarantees that these diamonds are conflict free based on personal knowledge and/or written guarantees provided by supplier of these diamonds.
                                    </div>
                            </div>
                        </div >
                    :
                    <>
                        {data.paymentMethod === "Credit Card Payment"?<div className="row">
                                <div className="col-md-4">
                                    <div className="font-size-h4 font-weight-bold mb-2">{props.language.INVOICE_DETAIL_PAYMENT_METHOD}</div>
                                    <div className='font-size-lg'>
                                        <div>{data.paymentMethod}</div>
                                    </div>
                                </div >
                                <div className="col-md-8">
                                    <div className="font-size-h4 font-weight-bold mb-3">Declaration :</div>
                                    <div className="font-size-lg mb-3">
                                        1) We Declare that this Invoice shows the actual price of the goods described and that all particulars are true and correct.
                                    </div>
                                    <div className="font-size-lg mb-3">
                                        2) The diamonds herein invoiced have been purchased from legitimate sources not involved in funding conflict and in compliance with United Nations Resolutions.
                                    </div>
                                    <div className="font-size-lg mb-3">
                                        3) The seller hereby guarantees that these diamonds are conflict free based on personal knowledge and/or written guarantees provided by supplier of these diamonds.
                                    </div>
                                </div>
                        </div >:<div className="row">
                                <div className="col-md-4">
                                    <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_BANK_DETAILS}</div>
                                    <div className='font-size-lg'>
                                        <div>Bank Name : CITI BANK (HONG KONG) LIMITED</div>
                                        <div>Account Name : Diamonds On Call Limited</div>
                                        <div>Account Number : 39051002000</div>
                                        <div>Bank Address : Citi Tower, 83 Hoi Bun Road,</div>
                                        <div>One Bay East, Kwun Tong</div>
                                        <div>SWIFT Code : CITIHKAXXXX</div>
                                        <div>Intermediary bank : Citi Bank New York</div>
                                        <div>Intermediary swift bank code: CITIUS33XXX</div>
                                        <div>ABA NO: 021000089</div>
                                    </div>
                                </div >
                                <div className="col-md-8">
                                    <div className="font-size-h4 font-weight-bold mb-3">Declaration :</div>
                                    <div className="font-size-lg mb-3">
                                        1) We Declare that this Invoice shows the actual price of the goods described and that all particulars are true and correct.
                                    </div>
                                    <div className="font-size-lg mb-3">
                                        2) The diamonds herein invoiced have been purchased from legitimate sources not involved in funding conflict and in compliance with United Nations Resolutions.
                                    </div>
                                    <div className="font-size-lg mb-3">
                                        3) The seller hereby guarantees that these diamonds are conflict free based on personal knowledge and/or written guarantees provided by supplier of these diamonds.
                                    </div>
                                </div>
                        </div >}
                        </>
                    }
                </div>
            </div>
            </> :  <div className="col-12 text-center">No Records Found for this invoice number</div> }
           </>
           : <InvoiceDetailSkeleton /> } 
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={alertOpen}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={alertMessage}
                />
            </Snackbar>
        </>
    )
}